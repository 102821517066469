import styled from 'styled-components'
import { fonts, colors, breakPoints } from '@constants/styles'
import Modal from 'react-modal'

export const DeliverySectionWrapper = styled.div({
  paddingTop: 16,
  textAlign: 'left',
})

export const DeliveryForm = styled.form({
  borderBottom: `solid 1px ${colors.lightGrey}`,
  padding: '0.6em 0.6em 0 0.6em',
  '.professional-delivery-text': {
    '@media screen and (min-width: 768px)': {
      paddingTop: '.625rem',
    },
  },
  h3: {
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '.7rem',
    '@media screen and (max-width: 639px)': {
      fontSize: `${fonts.txtSmall}`,
    },
  },
  h4: {
    marginTop: '0.5em',
    p: {
      display: 'inline',
      padding: '0 0 0 0.25em',
      '&.bold': {
        fontWeight: '600',
      },
    },
  },
  'input[type="text"]': {
    margin: '1.5em 1em 0.5em 1em',
    lineHeight: '20px',
    padding: '10px',
    borderRadius: '5px',
  },
  '.shipping': {
    width: '60px',
    float: 'left',
    padding: '0 5px',
  },
  '.shipping-box': {
    width: '30px',
  },
  '.delivery-radio-container': {
    padding: '0.5rem',
    fontSize: `${fonts.txtSmall}`,
    h4: {
      fontWeight: '600',
      fontSize: `${fonts.txtLarge}`,
    },
    p: {
      fontSize: `${fonts.txtMedium}`,
    },
    '.text-disclaimer': {
      fontSize: `${fonts.txtSmall}`,
    },
    '.radio-btn': {
      maxWidth: '1em',
      minWidth: '1em',
    },
    '.bold': {
      display: 'inline-block',
      fontWeight: '600',
      fontSize: `${fonts.txtSmall}`,
      '&:first-child': {
        marginBottom: '.5em',
      },
      '&.delivery-cost': {
        fontSize: '1.15rem',
        margin: '0.5rem 0',
        '@media screen and (min-width: 768px)': {
          margin: '1rem 0',
        },
      },
    },
    '.change-delivery-btn': {
      color: `${colors.primary}`,
      fontSize: `${fonts.txtSmall}`,
      fontWeight: '600',
      marginTop: '0.25rem',
      textAlign: 'left',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '.help-us': {
      maxWidth: '100%',
      minWidth: '100%',
      maxHeight: '5em',
      minHeight: '5em',
      margin: '0px',
      borderColor: `${colors.lightGrey}`,
      resize: 'none',
    },
    '.char-limit': {
      float: 'right',
      marginRight: '0.1em',
      fontSize: `${fonts.txtSmall}`,
    },
    '.text-label': {
      marginLeft: '0.1em',
      fontSize: `${fonts.txtSmall}`,
    },
  },
  '.delivery-product-container': {
    border: 'none',
    '&.no-border': {
      border: 'none !important',
    },
    '@media screen and (min-width: 1025px)': {
      paddingRight: '1em',
      borderRight: `solid 1px ${colors.lightGrey}`,
    },
  },
  '.date-picker-container': {
    textAlign: 'center',
    '@media screen and (max-width: 639px)': {
      marginLeft: '-0.7em',
    },
    '.date-picker-wrapper': {
      width: '18.92em',
      margin: 'auto',
    },
  },
})

export const DeliveryCondensedWrapper = styled.div`
  text-align: left;
  margin-left: 1em;
  .provided-instructions-container {
    padding: 0.5em 0;
    .provided-instructions {
      display: inline;
      margin-right: 0.4em;
      &.bold {
        font-weight: 600;
      }
    }
  }
  .deliver-section,
  .shipped-section {
    margin-bottom: 1rem;
    margin-right: 5%;
    @media screen and (max-width: ${breakPoints['large-max']}) {
      border-bottom: solid 1px ${colors.lightGrey};
    }
    h3 {
      font-weight: 600;
      text-align: left;
    }
    h4 {
      p,
      div {
        display: inline;
        padding-right: 0.25em;
        @media screen and (max-width: ${breakPoints.small}) {
          font-size: ${fonts.txtSmall};
        }
        &.bold {
          display: inline-block;
          font-weight: 600;
        }
      }
    }
    .distribution-center-button {
      font-size: 18px;
      @media screen and (max-width: ${breakPoints['large-max']}) {
        font-size: 15px;
      }
    }
  }
  .distribution-center-popup {
    padding-right: 0 !important;
  }
`

export const DeliveryProductWrapper = styled.div`
  background: ${colors.primaryInvert};
  width: 100%;
  margin: 0.5rem 0;
  min-height: 2rem;
  &.separator {
    border-bottom: solid 1px ${colors.lightGrey};
  }
  .card {
    display: flex;
    padding: 0.5rem 0;
  }
  .product-container {
    height: auto;
    margin: 0.5rem 0;
    .product-image-container {
      height: auto;
      margin: 0 1em;
      text-align: center;
      .product-image {
        max-width: 3rem;
        max-height: 3rem;
        display: inline-block;
        width: auto;
        height: auto;
        @media screen and (min-width: ${breakPoints['large-min']}) {
          max-width: 8rem;
          max-height: 8rem;
          margin-bottom: 0.5rem;
        }
      }
    }
    .product-info {
      a {
        display: block;
        height: 100%;
        font-weight: 400;
        font-size: ${fonts.txtXsmall};
        text-transform: unset;
        color: ${colors.darkGrey};
        margin-left: 0.5em;
        p {
          font-size: ${fonts.txtXsmall};
          &:hover {
            color: ${colors.primary};
            text-decoration: underline;
          }
        }
      }
      .warranty-label {
        display: block;
        margin-left: 1em;
      }
    }
    .quantity {
      text-align: right;
      font-size: ${fonts.txtXsmall};
    }
  }
`

export const DistributionCenterPopupWrapper = styled.div`
  display: inline;
  position: relative;
  .distribution-center-button {
    font-size: 18px;
    color: ${colors.primary};
    font-weight: 400;
    text-align: left;
    @media screen and (max-width: ${breakPoints['large-max']}) {
      font-size: 15px;
    }
    text-decoration: underline dotted;
    text-underline-offset: 2px;
  }
  .popup {
    background: #ffffff;
    position: absolute;
    min-width: 13rem;
    overflow-y: visible;
    border: solid 2px ${colors.lightGrey};
    padding: 10px;
    margin-left: 0;
    top: 1.25rem;
    z-index: 10;
    p {
      display: inline-block !important;
      margin-bottom: 0.25rem;
      font-size: ${fonts.txtSmall};
    }
    a {
      padding-left: 0.25rem;
      color: ${colors.primary};
      font-size: ${fonts.txtSmall};
      &:hover {
        text-decoration: underline;
      }
    }
    &.container {
      width: 100%;
      height: auto;
      margin: 0 auto;
      padding: 0.5rem;
      display: block;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
  }
`

export const DeliveryDatePickerWrapper = styled.div`
  width: 100%;
  padding: 2rem; // Todo mobile needs reduced padding
  &.express {
    .slider {
      .slick-prev,
      .slick-next {
        top: 38% !important;
        z-index: 0;
        @media screen and (min-width: ${breakPoints['large-min']}) {
          top: 13% !important;
        }
      }
    }
  }
  .slider {
    display: block !important;
    .slick-list {
      @media screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        width: 90% !important;
        margin: 0 auto;
      }
      @media screen and (max-width: ${breakPoints.small}) {
        width: 85% !important;
        margin: 0 auto;
      }
    }
    .slick-prev,
    .slick-next {
      top: 49% !important;
      @media screen and (min-width: ${breakPoints['large-min']}) {
        top: 16% !important;
      }
    }
    .slick-slider {
      margin: 0 -1em !important;
      @media screen and (min-width: ${breakPoints.medium}) {
        margin: 0 -2em !important;
        padding: 0 3rem !important;
      }
      @media screen and (min-width: ${breakPoints['large-min']}) {
        padding: 0 4rem !important;
      }
      .slick-prev,
      .slick-next {
        @media screen and (min-width: ${breakPoints['large-min']}) {
          transform: scale(2, 2);
        }
      }
    }
    .slick-slide {
      padding: 0 0.25em !important;
      margin-bottom: 5px;
    }
    .slick-list {
      overflow-y: visible;
      @media screen and (max-width: ${breakPoints.small}) {
        padding: 0.66rem 0 !important;
      }
      @media screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        padding: 0.5rem 0 !important;
      }
    }
    .slider {
      margin-top: 1.5em !important;
      width: 100%;
      padding: 2rem;
      @media screen and (max-width: ${breakPoints['large-max']}) {
        margin-top: 0 !important;
      }
    }
    .date {
      border: solid 1px ${colors.lightGrey};
      text-align: center;
      &.selected-date {
        background: ${colors.primary};
        p {
          color: ${colors.primaryInvert};
        }
        &:hover {
          background: ${colors.primary};
        }
      }
      &:hover {
        background: ${colors.lighterGrey};
        cursor: pointer;
      }
      .month {
        text-transform: uppercase;
        font-weight: 400;
        font-size: ${fonts.txtMediumUp};
        @media screen and (max-width: ${breakPoints['large-max']}) {
          font-size: ${fonts.txtMedium};
        }
        @media screen and (max-width: ${breakPoints.small}) {
          font-size: ${fonts.txtSmall};
        }
      }
      .day {
        font-weight: 700;
        font-size: 4.2em;
        line-height: 1;
        @media screen and (max-width: ${breakPoints['large-max']}) {
          font-size: 250%;
        }
        @media screen and (max-width: ${breakPoints.small}) {
          font-size: 220%;
        }
      }
      .weekday,
      .hours {
        text-transform: uppercase;
        font-weight: 400;
        font-size: ${fonts.txtMedium};
        margin-top: 0.3em;
        @media screen and (max-width: ${breakPoints['large-max']}) {
          font-size: ${fonts.txtXsmall};
        }
      }
      .hours {
        padding-top: 0.2rem;
        padding-bottom: 0.5rem;
      }
    }
    .restrictions {
      color: ${colors.darkGrey} !important;
      font-weight: 400;
      width: 100%;
      text-align: center;
      margin-top: 0.4rem;
      margin-bottom: -2.5rem;
      height: 2.1rem;
      font-size: ${fonts.txtSmall};
      @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        font-size: ${fonts.txtXsmall};
      }
      @media screen and (max-width: ${breakPoints.small}) {
        font-size: ${fonts.txtMini};
      }
    }
  }
`

export const DeliveryModalWrapper = styled(Modal)`
  color: ${colors.darkerGrey};

  &:focus {
    outline: none;
  }

  .update-delivery {
    width: 300px;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
    box-sizing: border-box;
    text-transform: uppercase;
    background-color: #0053a0;
    color: #ffffff;
    margin-bottom: 1rem;
    @media screen and (min-width: ${breakPoints.medium}) {
      width: 15rem;
      padding: 0.75rem;
      margin-bottom: 1.5rem;
    }

    &:hover {
      background-color: ${colors.lightBlue};
    }

    &:focus {
      outline: solid 3px -webkit-focus-ring-color;
    }

    .loader {
      height: 26px;
      margin-top: -5px;
      margin-bottom: -5px;
    }
  }

  .close-modal {
    position: absolute;
    top: -3em;
    right: -1.75em;
    background: ${colors.primary};
    width: 4em;
    height: 4em;
    border-radius: 50%;
    @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
      top: -1.5em;
      right: -1.5em;
    }
    @media screen and (max-width: ${breakPoints.small}) {
      top: -1.4em;
      right: -1.1em;
      width: 3.5em;
      height: 3.5em;
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 3em;
      height: 3em;
      margin: auto;
      background-size: 100% !important;
    }
  }

  .modal-content {
    background-color: ${colors.white};
    border: solid ${colors.lightGrey} 0.4rem;
    width: 70%;
    position: fixed;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 60rem;
    max-width: 60rem;
    @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
      height: 90%;
      max-height: 90% !important;
    }
    @media only screen and (max-width: ${breakPoints['large-max']}) {
      min-width: 10rem;
      width: 95%;
      max-height: 85%;
    }

    .grid-x {
      height: 100%;

      .update-container {
        text-align: center;
        padding-top: 0.75rem;
        @media only screen and (min-width: 374px) and (max-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
          position: fixed;
          bottom: 0;
          width: 100%;
          height: 3.75rem;
          background: ${colors.lighterGrey};
          padding: 0.5rem;
        }

        .error {
          color: ${colors.red};
          font-size: ${fonts.txtSmall};
          margin-top: 0;
          margin-bottom: 1rem;
          @media screen and (min-width: ${breakPoints.medium}) {
            margin-top: -1rem;
            font-size: ${fonts.txtMedium};
          }
        }

        .update-delivery {
          padding: 10px 20px;
          font-size: 1rem;
          border-radius: 5px;
          text-align: center;
          font-weight: 600;
          box-sizing: border-box;
          text-transform: uppercase;
          background-color: #0053a0;
          color: #ffffff;
          @media screen and (min-width: ${breakPoints.medium}) {
            width: 15rem;
            padding: 0.75rem;
            margin-bottom: 1.5rem;
          }

          &:hover {
            background-color: ${colors.lightBlue};
          }

          &:focus {
            outline: solid 3px -webkit-focus-ring-color;
          }

          .loader {
            height: 26px;
            margin-top: -5px;
            margin-bottom: -5px;
          }
        }
      }

      .content-container {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 71.25vh;
        @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
          max-height: 83.25vh;
        }

        .card {
          width: 100%;
          height: 100%;
          margin: 0 auto;
          padding: 0.5rem;
          display: flex;
          align-content: center;
          overflow: auto;
        }

        .title {
          font-size: ${fonts.txtMedium};
          margin: 0 auto !important;
          text-align: center;
          @media screen and (min-width: ${breakPoints.medium}) {
            font-size: ${fonts.txtXlarge};
            width: 20rem;
          }

          h2 {
            display: inline;
            font-weight: 400;
            margin: 0 auto;
            line-height: 3;
            @media screen and (min-width: ${breakPoints.medium}) {
              line-height: 4;
              margin-left: 1.25rem;
            }
          }

          .date-picker {
            padding: 0.5rem;
          }

          .radio-container {
            background: ${colors.primaryInvert};
            text-align: left !important;
            padding: 0 1rem;
            overflow: hidden;
            @media screen and (min-width: ${breakPoints.medium}) {
              padding: 1rem 2rem;
              margin-bottom: 1.5rem;
            }
            @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
              margin-bottom: 3.75rem;
            }

            .text-me {
              position: absolute;
              @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
                position: relative;
                top: 6px;
                right: -25px;
              }
            }

            .icon {
              &.shipping {
                margin-left: -0.5em;
              }

              &.store {
                margin-left: 0.3rem;
                margin-right: 0.5rem;
              }
            }

            .divider {
              border-bottom: solid 1px ${colors.mediumGrey};
              margin-top: 1.1rem;
              margin-bottom: 1.2rem;
              @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
                margin-top: 0;
              }
            }
          }

          .cell {
            text-align: center;
          }

          .delivery-selection {
            line-height: 2;
            @media screen and (max-width: ${breakPoints['large-max']}) {
              font-size: ${fonts.txtSmall};
            }
            @media screen and (min-width: ${breakPoints['large-min']}) {
              display: inline;
            }

            .bold {
              font-weight: 700;
              margin-left: 1.75rem;
              font-size: ${fonts.txtSmall};
              @media screen and (max-width: ${breakPoints.small}) {
                font-size: ${fonts.txtXsmall};
              }

              &.inline {
                display: inline;
                margin: 0;
                margin-right: 0.25rem;
                font-size: ${fonts.txtSmall};
                @media screen and (min-width: ${breakPoints.medium}) {
                  font-size: ${fonts.txtMedium};
                }
              }
            }
          }

          .margin-left {
            display: inline;
            margin-left: 0.25rem;
          }

          .automated-message {
            margin-left: 1.75rem;
            margin-top: 0.5rem;
            max-width: 100%;
            font-size: ${fonts.txtXsmall};
          }

          .prof-delivery {
            font-size: ${fonts.txtSmall};
            @media screen and (max-width: ${breakPoints['large-max']}) {
              margin-left: 1.75rem;
            }
            @media screen and (max-width: ${breakPoints.small}) {
              font-size: ${fonts.txtXsmall};
            }
            @media screen and (min-width: ${breakPoints['large-min']}) {
              width: 90%;
              display: inline-block;
            }
          }

          .delivered-on {
            margin-left: 1.75rem;
            display: inline;
            font-size: ${fonts.txtSmall};
            @media screen and (max-width: ${breakPoints.small}) {
              font-size: ${fonts.txtXsmall};
            }
          }

          .note {
            width: 100%;
            text-align: center;
            font-size: ${fonts.txtSmall};
            @media screen and (max-width: ${breakPoints.small}) {
              font-size: ${fonts.txtXsmall};
            }
          }
        }
      }
    }
  }
`

export const PickupList = styled.ul`
  margin-left: 1.2rem;
  padding-left: 0;
  list-style-type: disc;
  line-height: 1rem;
  font-size: ${fonts.txtXsmall};
  @media screen and (min-width: ${breakPoints.medium}) {
    font-size: ${fonts.txtSmall};
    line-height: 1.5rem;
  }
`

export const PickupListHead = styled.p`
  margin: 0.5rem 0;
  font-size: $txt-xsmall;
  @media screen and (min-width: ${breakPoints.medium}) {
    font-size: ${fonts.txtSmall};
  }
`
