const axios = require('axios')

export const getOrderList = body =>
  axios.post(`${process.env.GATSBY_ORDER_MANAGEMENT_URL}/getRelatedOrders`, body).then(response => response.data)

export const getOrdersHistory = (query, timeout, authtoken) =>
  axios
    .get(`${process.env.GATSBY_ORDER_MANAGEMENT_URL}/ordersHistory${query}`, { timeout, headers: { authtoken } })
    .then(response => response.data)
    .catch(error => {
      if (error?.response?.data?.error?.message) {
        return { error: error.response.data.error.message }
      }
      return error
    })

export const getOrderDetails = body =>
  axios.post(`${process.env.GATSBY_ORDER_MANAGEMENT_URL}/getRtgOrder`, body).then(response => response.data)

export const setPayOnline = body =>
  axios.post(`${process.env.GATSBY_ORDER_MANAGEMENT_URL}/payonline`, body).then(response => response.data)

export const getOrderHistoryByKey = async key => {
  const response = await fetch(`${process.env.GATSBY_ORDER_MANAGEMENT_URL}/ordersHistoryByKey/?orderKey=${key}`)
  if (!response.ok) {
    throw new Error('Failed to fetch order history')
  }
  return response.json()
}

export const getOrderHistoryByEmail = async (email, authtoken) => {
  const response = await fetch(`${process.env.GATSBY_ORDER_MANAGEMENT_URL}/orders?email=${email}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'x-token': authtoken,
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch order history')
  }
  return response.json()
}

export const getPremiumDeliveryCharge = (orderId, zip) =>
  axios
    .get(`${process.env.GATSBY_ORDER_MANAGEMENT_URL}/premiumDelivery/?orderId=${orderId}&zipcode=${zip}`)
    .then(response => response.data)
